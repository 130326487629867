import Api from '../services/api'
import moment from 'moment';

export default {
    namespaced: true,

    state: () => ({
      cookies: []
    }),
    getters: {
        cookies (state) {
          return state.cookies.map(x => {
            x.createdAt = moment(x.createdAt).format("YYYY-MM-DD hh:mm:ss")
            return x;
          });
        }
    },
    mutations: {
      setCookies: (state, cookies) => {
        state.cookies = cookies;
      },
      setCookieSpending: (state, data) => {
        let arr = [...state.cookies];
        let index = arr.findIndex(x => x._id == data.id);
        if (index > -1) {
            arr[index] = {
                ...arr[index],
                ...data
            }
        }
        state.cookies = arr;
      },
      updateCookie: (state, data) => {
        let arr = [...state.cookies];
        let index = arr.findIndex(x => x._id == data._id);
        if (index > -1) {
            arr[index] = {
                ...arr[index],
                ...data
            }
        }
        state.cookies = arr;
      },
      refreshCookieAmount: (state, data) => {
        console.log('-----data', data);
        let arr = [...state.cookies];
        let index = arr.findIndex(x => x._id == data._id);
        if (index > -1) {
            arr[index] = {
                ...arr[index],
                ...data
            }
        }
        state.cookies = arr;
      }
    },
    actions: {
        getCookies: async (context, query) => {
            try {
              let result = await Api.getCookies(query);
              if (result.success) {
                context.commit("setCookies", result.data.cookies)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        getCookieSpending: async (context, id) => {
            try {
              let result = await Api.getCookieSpending(id);
              if (result.success) {
                context.commit("setCookieSpending", {id, daily_rbx_sent: result.data.daily_rbx_sent})
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        updateCookie: async (context, data) => {
            try {
              let result = await Api.updateCookie(data);
              console.log('---result', result);
              if (result.success) {
                context.commit("updateCookie", data)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        refreshCookieAmount: async (context, _id) => {
            try {
              let result = await Api.refreshCookieAmount(_id);
              if (result.success) {
                context.commit("refreshCookieAmount", {_id, ...result.data})
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        }
    }
    
}