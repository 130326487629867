import Api from '../services/api'
import moment from 'moment';

export default {
    namespaced: true,

    state: () => ({
      payments: []
    }),
    getters: {
        payments (state) {
          return state.payments.map(x => {
            x.createdAt = moment(x.createdAt).format("YYYY-MM-DD hh:mm:ss")
            return x;
          });
        }
    },
    mutations: {
      setPayments: (state, payments) => {
        state.payments = payments;
      },
      addPayment: (state, payment) => {
        state.payments.push(payment);
      },
      updatePayment: (state, data) => {
        let arr = [...state.payments];
        let index = arr.findIndex(x => x._id == data._id);
        if (index > -1) {
            arr[index] = {
                ...arr[index],
                ...data
            }
        }
        state.payments = arr;
      },
      deletePayment: (state, id) => {
        let arr = [...state.payments];
        let index = arr.findIndex(x => x._id == id);
        if (index > -1) {
            arr.splice(index, 1)
        }
        state.payments = arr;
      }
    },
    actions: {
        getPayments: async (context, query) => {
            try {
              let result = await Api.getPayments(query);
              if (result.success) {
                context.commit("setPayments", result.data.payments)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        addPayment: async (context, payment) => {
            try {
              let result = await Api.addPayment(payment);
              if (result.success) {
                context.commit("addPayment", result.data.payment)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        updatePayment: async (context, data) => {
            try {
              let result = await Api.updatePayment(data);
              if (result.success) {
                context.commit("updatePayment", data)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        deletePayment: async (context, id) => {
            try {
              let result = await Api.deletePayment(id);
              if (result.success) {
                context.commit("deletePayment", id)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        }
    }
    
}