<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
          <v-app-bar-nav-icon
            @click="toggleSidebar"
          ></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="is_authenticated"
        text
        @click="logout"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <Sidebar 
      ref="Sidebar"
    />

    <v-main>

      <v-alert
        dense
        dismissible
        prominent
        type="error"
        ref="Alert"
        :value="false"
      >
        {{ errorMessage }}
        <template v-slot:close="{ toggle }">
          <v-btn  color="primary" dark @click="hideAlert(toggle)"> Dismiss </v-btn>
        </template>
      </v-alert>
      <router-view/>
    </v-main>


  </v-app>
</template>

<script>

import {setToken} from './services/api'
import { mapGetters } from 'vuex'
import Sidebar from './components/Sidebar.vue'

export default {
  name: 'App',
  components: {
    Sidebar
  },
  data: () => ({
    //
  }),
  mounted() {
    setToken(this.$store.getters.token)
  },
  computed: {
    errorMessage() {
      if (this.$store.getters.error_message) {
      this.showErrorAlert(this.$store.getters.error_message)
      }
      return this.$store.getters.error_message;
    },
    ...mapGetters([
        'current_user',
        'is_authenticated'
      ])
  },
  methods: {
    logout: function() {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    showErrorAlert () {
      if (this.$refs.Alert) {
        this.$refs.Alert.toggle()
      } 
    },
    hideAlert(toggle) {
      this.$store.commit("clearError", "");
      toggle()
    },
    toggleSidebar() {
      this.$refs.Sidebar.toggle()
    }
  }
};
</script>
