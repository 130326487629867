import axios from 'axios'
const _api = axios.create({
//  baseURL: 'https://adm-panel.rbxboost.com/api',
//   baseURL: 'http://localhost:3001/api',
   baseURL: 'https://adm-supplier.rbxadder.com/api',
  timeout: 10000,
})

export const setToken = (token) => {
    console.log('-------token', token);
    _api.defaults.headers.common = {
        "Authorization": `bearer ${token}`
    };
}

setToken(localStorage.getItem("v-admin-token"))


let Api = {};

Api.login = async (email, password) => {
    let result = await _api.post('/auth/login', {email, password});
    return result.data;
}


Api.getUser = async (id) => {
    let result = await _api.get('/users/'+id);
    return result.data;
}


Api.getUsers = async (data) => {
    let result = await _api.get('/users', {
        params: data
    });
    return result.data;
}

Api.addUser = async (data) => {
    let result = await _api.post('/users', data);
    return result.data;
}

Api.updateUser = async (data) => {
    let result = await _api.patch(`/users/${data._id}`, data);
    return result.data;
}

Api.getCookies = async (data) => {
    let result = await _api.get('/cookies', {
        params: data
    });
    return result.data;
}

Api.getCookieSpending = async (id) => {
    let result = await _api.get(`/cookies/${id}/spending`);
    return result.data;
}

Api.refreshCookieAmount = async (id) => {
    let result = await _api.get(`/cookies/${id}/amount`);
    return result.data;
}

Api.addCookie = async (value) => {
    let result = await _api.post('/cookies', {value});
    return result.data;
}

Api.updateCookie = async (data) => {
    let result = await _api.patch('/cookies/'+data._id, data);
    return result.data;
}

Api.deleteCookie = async (id) => {
    let result = await _api.get(`/cookies/${id}/delete`);
    return result.data;
}

Api.getPayouts = async (data) => {
    let result = await _api.get('/payouts', {
        params: data
    });
    return result.data;
}

Api.getPayments = async (data) => {
    let result = await _api.get('/payments', {
        params: data
    });
    return result.data;
}

Api.addPayment = async (data) => {
    let result = await _api.post('/payments', data);
    return result.data;
}

Api.updatePayment = async (data) => {
    let result = await _api.patch('/payments/'+data._id, data);
    return result.data;
}

Api.deletePayment= async (id) => {
    let result = await _api.get(`/payments/${id}/delete`);
    return result.data;
}

export default Api;

