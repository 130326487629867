import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Users from '../views/Users.vue'
import Cookies from '../views/Cookies.vue'
import Payments from '../views/Payments.vue'
import Payouts from '../views/Payouts.vue'
import User from '../views/User.vue'
import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      title: 'Users'
    }
  },
  {
    path: '/users/:id',
    name: 'User',
    component: User,
    meta: {
      title: 'User'
    }
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies,
    meta: {
      title: 'Cookies'
    }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
    meta: {
      title: 'Payments'
    }
  },
  {
    path: '/payouts',
    name: 'Payouts',
    component: Payouts,
    meta: {
      title: 'Payouts'
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      title: 'Login'
    }
  }
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.getters.is_authenticated ) next({ name: 'Login' })
  if (to.name === 'Login' && store.getters.is_authenticated  ) next({ name: 'Home' })
  else next()
});

router.afterEach((to) => {
  Vue.nextTick(() => {
      document.title = to.meta.title;
  });
});



export default router
