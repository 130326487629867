<template>
  <div
    class="mt-8"
  >
    <v-row
        class="my-2"
    >
       <h1
        class="text-xl"
       >Payments</h1>
       <v-spacer></v-spacer>

        <v-btn
        v-if="user_id"
            color="primary"
            elevation="2"
            large
            @click="addItem()"
        >
            Add Payment
        </v-btn>
    </v-row>
        <v-data-table
        dense
        :headers="headers"
        :items="this.payments"
        item-key="name"
        class="elevation-1"
        :loading="this.loading"
    >
        <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog" max-width="500px">
              <v-card
                class="px-4 py-4"
              >
                  <v-card-title class="text-h5">Add New Payment</v-card-title>
                    <v-text-field
                        v-model="editedItem.amount"
                        :error-messages="amountErrors"
                        label="Amount"
                        type="number"
                        required
                        @input="$v.editedItem.amount.$touch()"
                        @blur="$v.editedItem.amount.$touch()"
                    ></v-text-field>
                      <v-textarea
                        name="notes"
                        label="Notes"
                        v-model="editedItem.notes"
                      ></v-textarea>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="blue " text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Save</v-btn> -->
                  <v-btn
                      color="default"
                      elevation="2"
                      @click="close()"
                  >
                      Cancel
                  </v-btn>
                  <v-btn
                      color="primary"
                      elevation="2"
                      @click="save()"
                      :disabled="invalid"
                  >
                      Save
                  </v-btn>
                  <v-spacer></v-spacer>
                  </v-card-actions>
              </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
              color="primary"
              elevation="2"
              class="mr-4"
              small
              @click="addItem(item)"
          >
              Edit
          </v-btn>
          <v-btn
              color="error"
              elevation="2"
              small
              @click="deleteItem(item)"
          >
              Remove
          </v-btn>
        </template>

        <template v-slot:item.user="{ item }">
          <a
            :href="`/#/users/${item.user._id}`"
            target="_blank"
          >
            {{item.user.username}}
          </a>
        </template>
    </v-data-table>

  </div>

</template>

<script>
  import { mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

    export default {
    mixins: [validationMixin],
    validations: {
      editedItem: {
        amount: {required}
      }
      
    },
    props:["user_id"],
    data: () => ({
      loading: false,
      headers: [
        // { text: 'ID', value: '_id' },
        { text: 'user', value: 'user' },
        { text: 'Date', value: 'createdAt' },
        { text: 'Amount', value: 'amount' },
        { text: 'Notes', value: 'notes' },
        { text: 'Actions', value: 'actions', sortable: false },

      ],
      defaultItem: {
        amount: "",
        notes: ""
      },
      editedItem: {},
      dialogDelete: false,
      dialog: false
    }),
    mounted() {
      this.defaultItem.user_id = this.user_id
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      amountErrors () {
        const errors = []
        if (!this.$v.editedItem.amount.$dirty) return errors
        !this.$v.editedItem.amount.required && errors.push('Amount is required')
        return errors
      },
      invalid() {
        return this.$v.$invalid
      },
      ...mapGetters("payment", [
        'payments',
      ])
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      }
    },
    methods: {
      async getPayments (query, search_by, order_by, order, limit) {
        this.loading = true;
        await this.$store.dispatch("payment/getPayments", {query, search_by, order_by, order, limit});
        this.loading = false
      },
      async addPayment () {
        if (this.invalid) return; 
        this.loading = true;
        await this.$store.dispatch("payment/addPayment", this.editedItem);
        this.loading = false
        this.dialog = false
      },
      async updatePayment () {
        if (this.invalid) return; 
        this.loading = true;
        console.log('------update', this.editedItem);
        await this.$store.dispatch("payment/updatePayment", this.editedItem);
        this.loading = false
        this.dialog = false
      },
      addItem(item) {
        if (item) {
          this.editedItem = item;
        } else {
          this.editedItem = Object.assign({}, this.defaultItem)
        }
        this.dialog = true
      },
      deleteItem (item) {
        console.log('-----delete item', item);
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      save() {
        if (this.editedItem._id) {
          this.updatePayment()
        } else {
          this.addPayment()
        }
      },

      async deleteItemConfirm () {
        await this.$store.dispatch("payment/deletePayment", this.editedItem._id);
        this.closeDelete()
      },


      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {}
        })
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      }
    },
  }
</script>