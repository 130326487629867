<template>
  <div>
    <v-row
        class="my-2"
    >
       <h1
        class="text-xl"
       >Cookies</h1>
    </v-row>
    <v-data-table
        dense
        :headers="headers"
        :items="this.cookies"
        item-key="name"
        class="elevation-1"
        :loading="this.loading"
    >
        <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-if="dialogShow" v-model="dialogShow" max-width="500px">
                <v-card
                  class="px-8 py-8"
                >
                    <p>{{ selectedItem.value }}</p>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeShow">Close</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-if="dialogGraph" v-model="dialogGraph" 
            >
               <v-card 
                class="small py-8"
              >
                <h1
                  class="text-xl mb-8 "
                >Daily Robux Spent</h1>
                <line-chart
                  v-if="datacollection"
                  :chart-data="datacollection"
                  style="height: 400px"
                  :options="datacollection.options"
                ></line-chart>
                <div
                    v-else
                    style="display: flex"
                  class="flex justify-center py-8"
                >
                  <v-progress-circular
                    center
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>

              </v-card>
            </v-dialog>
        </template>
        

        <template v-slot:item.actions="{ item }">
          <v-btn
              :color="item.status == 'active' ? 'error' : 'success' "
              elevation="2"
              class="mr-2"
              small
              @click="disableCookie(item._id, item.status == 'active' ? 'disabled' : 'active')"
          >
               {{ item.status == 'active' ? 'Disable' : 'Enable' }}
          </v-btn>
          <v-btn
              :color="item.paused ? 'success' : 'error' "
              elevation="2"
              class="mr-2"
              small
              @click="pauseCookie(item._id, !item.paused)"
          >
               {{ item.paused ? 'Resume' : 'Pause' }}
          </v-btn>
          <v-btn
              color="primary"
              elevation="2"
              class="mt-2 mb-2"
              small
              @click="showItemGraph(item)"
          >
              Daily spent
          </v-btn>
          <v-btn
              color="light-blue"
              style="color: #fff"
              elevation="2"
              class="mt-2 mb-2"
              small
              @click="refreshCookieAmount(item._id)"
          >
              Refresh Amount
          </v-btn>
        </template>

        <template v-slot:item.value="{ item }">
          <span
            style="cursor: pointer"
            @click="showItem(item)"
          >
            {{item.value.substring(0, 50) }}...
          </span>
        </template>

        <template v-slot:item.user="{ item }">
          <a
            :href="`/#/users/${item.user._id}`"
            target="_blank"
          >
            {{item.user.username}}
          </a>
        </template>
    </v-data-table>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex'
  import LineChart from "../components/LineChart.js";

  export default {
    props: ["user_id"],
    components: {
      LineChart
    },
    data: () => ({
      loading: false,
      headers: [
        // { text: 'ID', value: '_id' },
        { text: 'user', value: 'user' },
        { text: 'Cookie', value: 'value' },
        { text: 'Available Amount', value: 'available_amount' },
        { text: 'Total Rbx Spent', value: 'spent_amount' },
        { text: 'Total Rawbux Spent', value: 'rawbux_spent_amount' },
        { text: 'Total Spinbux Spent', value: 'spinbux_spent_amount' },
        { text: 'Status', value: 'status' },
        { text: 'Visible', value: 'active' },
        { text: 'Paused', value: 'paused' },
        { text: 'Notes', value: 'notes' },
        { text: 'Date', value: 'createdAt' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      defaultItem: {
        value: "",
      },
      editedItem: {},
      dialogDelete: false,
      dialogShow: false,
      dialogGraph: false,
      selectedItem: "",
      datacollection: null,
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      ...mapGetters("cookie", [
        'cookies',
      ])
    },
    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogShow (val) {
        val || this.closeShow()
      },
      dialogGraph (val) {
        val || this.closeGraph()
      },
    },

    created () {
    },

    methods: {
      async getCookies (query, search_by, order_by, order, limit) {
        this.loading = true;
        await this.$store.dispatch(`cookie/getCookies`, {query, search_by, order_by, order, limit});
        this.loading = false
      },
      async disableCookie(_id, status) {
        this.loading = true;
        await this.$store.dispatch(`cookie/updateCookie`, {_id, status });
        this.loading = false
        this.dialog = false
      },
      async pauseCookie(_id, paused) {
        this.loading = true;
        await this.$store.dispatch(`cookie/updateCookie`, {_id, paused });
        this.loading = false
      },
      async refreshCookieAmount(_id) {
        this.loading = true;
        await this.$store.dispatch(`cookie/refreshCookieAmount`, _id);
        this.loading = false
      },
      async getCookieSpending() {
        await this.$store.dispatch("cookie/getCookieSpending", this.selectedItem._id);
        this.loading = false;
        let index = this.cookies.findIndex(x => x._id == this.selectedItem._id);
        if (index > -1) {
          this.selectedItem = this.cookies[index];
          this.fillData()
        }
      },
      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      showItem(item) {
        this.selectedItem = item;
        this.dialogShow = true
      },
      showItemGraph(item) {
        this.selectedItem = item;
        this.getCookieSpending();
        this.dialogGraph = true
      },
      fillData () {
        this.datacollection = {
          labels: Object.keys(this.selectedItem.daily_rbx_sent),
          datasets: [
            {
 
              data: Object.values(this.selectedItem.daily_rbx_sent)
            }
          ],
          options: {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }]
            },
            legend: {
              display: false //This will do the task
            },
            responsive: true,
            maintainAspectRatio: false,
            // height: "10"
          }
        }
      },
      async deleteItemConfirm () {
        await this.$store.dispatch("deleteCookie", this.editedItem._id);
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {}
        })
      },
      closeShow () {
        this.dialogShow = false
        this.$nextTick(() => {
          this.editedItem = {}
        })
      },
      closeGraph () {
        this.dialogGraph = false
        this.$nextTick(() => {
          this.datacollection = null
        })
      },
    },
  }
</script>