<template>
  <v-container>
    <v-card
      class="px-8 py-2"
    >
      <v-card-title>Search</v-card-title>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-text-field
          v-model="query"
          label="Query"
        ></v-text-field>
        
        <v-select
          v-model="searchBy"
          :items="searchByData"
          label="Search By"
          item-text="name"

        ></v-select>

        <v-select
          v-model="orderBy"
          :items="orderByData"
          label="Order By"
          item-text="name"

        ></v-select>

        <v-select
          v-model="order"
          :items="orderData"
          label="Order"
          item-text="name"

        ></v-select>
        
        <v-text-field
          v-model="limit"
          label="Limit"
          type="number"
        ></v-text-field>

        <v-btn
          color="success"
          class="mr-4"
          @click="load"
        >
          Search
        </v-btn>
      </v-form>
    </v-card>
    <PayoutsTable
      ref="PayoutTable"
    ></PayoutsTable>
  </v-container>

</template>

<script>
  
  import PayoutsTable from "../components/PayoutsTable.vue";


  export default {
    name: 'Home',
    components: {
      PayoutsTable
    },
    data: () => ({
      loading: true,
      query: "",
      limit: 100,
      searchBy: "",
      orderBy: "_id",
      order: "desc",
      searchByData: [
        {name: "ID", value: "_id"},
        {name: "User", value: "user"},
        {name: "Cookie", value: "cookie"}
      ],
      orderByData: [
        {name: "ID", value: "_id"},
        {name: "User", value: "user"},
        {name: "Date", value: "createdAt"},
        {name: "Amount", value: "amount"},
      ],
      orderData: [{ value: "desc", name: "DESC" }, { value: "asc", name: "ASC" }],
    }),
    methods: {
      load() {
        this.$refs.PayoutTable.getPayouts(this.query, this.searchBy, this.orderBy, this.order, this.limit)
      }
    }
  }
</script>
