<template>
  <div
    class="mt-8"
  >
    <v-row
        class="my-2"
    >
       <h1
        class="text-xl"
       >Payouts</h1>
    </v-row>
        <v-data-table
        dense
        :headers="headers"
        :items="this.payouts"
        item-key="name"
        class="elevation-1"
        :loading="this.loading"
    >
        <template v-slot:item.user="{ item }">
          <a
            :href="`/#/users/${item.user._id}`"
            target="_blank"
          >
            {{item.user.username}}
          </a>
        </template>
    </v-data-table>

  </div>

</template>

<script>
  import { mapGetters } from 'vuex'

    export default {

    props:["user_id"],
    data: () => ({
      loading: false,
      headers: [
        { text: 'ID', value: '_id' },
        { text: 'user', value: 'user' },
        { text: 'Amount', value: 'amount' },
        { text: 'Cookie', value: 'cookie' },
        { text: 'Status', value: 'status' },
        { text: 'Notes', value: 'notes' },
        { text: 'Date', value: 'createdAt' },

      ],
    }),
    computed: {
      ...mapGetters("payout", [
        'payouts',
      ])
    },
    methods: {
      async getPayouts (query, search_by, order_by, order, limit) {
        this.loading = true;
        await this.$store.dispatch("payout/getPayouts", {query, search_by, order_by, order, limit});
        this.loading = false
      }
    },
  }
</script>