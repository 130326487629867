import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import Api, { setToken } from '../services/api'
import cookieModule from './cookieModule';
import paymentModule from './paymentModule';
import payoutModule from './payoutModule';
import UserModule from './userModule';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    is_authenticated: localStorage.getItem("v-admin-token") ? true : false,
    current_user: {},
    token: localStorage.getItem("v-admin-token"),
    error_message: ""
  },
  getters: {
    is_authenticated: (state) => {
      return state.is_authenticated;
    },
    token: (state) => {
      return state.token;
    },
    current_user: (state) => {
      return state.user;
    }
  },
  mutations: {
    setToken: (state, {access_token}) => {
      state.token = access_token
      state.is_authenticated = true
    },
    clearUser: (state) => {
      state.current_user = null;
      state.token = null;
      state.is_authenticated = false;
    },
    setError: (state, message) => {
      if (!state.error_message) {
        state.error_message = message;
      }
    },
    clearError: (state) => {
      state.error_message = ""
    }
  },
  actions: {
    login: async (context, {email, password}) => {
      let result = await Api.login(email, password);
      console.log('-------result', result);
      if (result.success) {
        localStorage.setItem("v-admin-token", result.data.access_token)
        context.commit("setToken", result.data)
        setToken(result.data.access_token);
        router.push('/')
      }
    },
    logout: (context) => {
      localStorage.removeItem("v-admin-token");
      context.commit("clearUser");
    }
  }
})


store.registerModule('user', UserModule)
store.registerModule('cookie', cookieModule)
store.registerModule('payment', paymentModule)
store.registerModule('payout', payoutModule)

export default store;