<template>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        bottom
        temporary
    >
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title 
                    class="text-h6"
                    @click="toHome"
                >
                    Application
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list
            dense
            nav
        >
            <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
            >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>

        </v-navigation-drawer>
</template>


<script>
    export default {
        name: "Sidebar",
        data: () => ({
            group: false,
            drawer: false,
            items: [
                { link: "/users", title: "users", icon: 'mdi-image'},
                { link: "/cookies", title: "cookies", icon: 'mdi-image'},
                { link: "/payments", title: "payments", icon: 'mdi-image'},
                { link: "/payouts", title: "payouts", icon: 'mdi-image'},
            ]
        }),
        methods: {
            toggle() {
                this.drawer = !this.drawer
            },
            toHome() {
                this.$router.push({name: "Home"})
            }
        }
    }
</script>