import Api from '../services/api'
import moment from 'moment';

export default {
    namespaced: true,
    state: () => ({
      payouts: []
    }),
    getters: {
        payouts (state) {
          return state.payouts.map(x => {
            x.createdAt = moment(x.createdAt).format("YYYY-MM-DD hh:mm:ss")
            return x;
          });
        }
    },
    mutations: {
      setPayouts: (state, payouts) => {
        state.payouts = payouts;
      }
    },
    actions: {
        getPayouts: async (context, query) => {
            try {
              let result = await Api.getPayouts(query);
              if (result.success) {
                context.commit("setPayouts", result.data.payouts)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        }
    }
    
}