import Api from '../services/api'

export default {
    namespaced: true,

    state: () => ({
      users: [],
      user: {}
    }),
    getters: {
        users (state) {
          return state.users;
        },
        user (state) {
          return state.user;
        }
    },
    mutations: {
      setUsers: (state, users) => {
        state.users = users;
      },
      setUser: (state, user) => {
        state.user = user;
      },
      addUser: (state, user) => {
        state.users.push(user);
      },
      updateUser: (state, data) => {
        state.user = {
          ...state.user,
          ...data
        }
      }
    },
    actions: {
        getUsers: async (context, query) => {
            try {
              let result = await Api.getUsers(query);
              if (result.success) {
                context.commit("setUsers", result.data.users)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        getUser: async (context, id) => {
            try {
              let result = await Api.getUser(id);
              if (result.success) {
                context.commit("setUser", result.data.user)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        addUser: async (context, user) => {
            try {
              let result = await Api.addUser(user);
              if (result.success) {
                context.commit("addUser", result.data.user)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
      
            return;
        },
        updateUser: async (context, user) => {
            try {
              let result = await Api.updateUser(user);
              if (result.success) {
                context.commit("updateUser", user)
              }
            } catch (error) {
              context.commit("setError", "Something Went Wrong, Please Try Again Later", {root: true})
            }
            return;
        },
    }
    
}