<template>
  <div>
    <v-row
        class="my-2"
    >
       <h1
        class="text-xl"
       >Users</h1>
       <v-spacer></v-spacer>

        <v-btn
            color="primary"
            elevation="2"
            large
            @click="addItem()"
        >
            Add User
        </v-btn>
    </v-row>
    <v-data-table
        dense
        :headers="headers"
        :items="users"
        item-key="name"
        class="elevation-1"
        :loading="this.loading"
    >
        <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog" max-width="500px">
              <v-card
                class="px-4 py-4"
              >
                  <v-card-title class="text-h5">Add New User</v-card-title>
                  <v-text-field
                      v-model="editedItem.username"
                      label="Username"
                      :error-messages="usernameErrors"
                      required
                      @input="$v.editedItem.username.$touch()"
                      @blur="$v.editedItem.username.$touch()"
                  ></v-text-field>
                  <v-text-field
                      v-model="editedItem.password"
                      :error-messages="passwordErrors"
                      label="Password"
                      required
                      @input="$v.editedItem.password.$touch()"
                      @blur="$v.editedItem.password.$touch()"
                  ></v-text-field>
                    <v-text-field
                      v-model="editedItem.rate"
                      :error-messages="rateErrors"
                      label="Rate"
                      type="number"
                      required
                      @input="$v.editedItem.rate.$touch()"
                      @blur="$v.editedItem.rate.$touch()"
                  ></v-text-field>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="blue " text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Save</v-btn> -->
                  <v-btn
                      color="default"
                      elevation="2"
                      @click="close()"
                  >
                      Cancel
                  </v-btn>
                  <v-btn
                      color="primary"
                      elevation="2"
                      @click="addUser()"
                      :disabled="invalid"
                  >
                      Save
                  </v-btn>
                  <v-spacer></v-spacer>
                  </v-card-actions>
              </v-card>
              </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
              color="primary"
              elevation="2"
              small
              :href="`/#/users/${item._id}`"
              target="_blank"
          >
              View
          </v-btn>
        </template>
    </v-data-table>

</div>

</template>

<script>
  import { mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'


  export default {
    mixins: [validationMixin],
    validations: {
      editedItem: {
        password: { required, minLength: minLength(8)  },
        username: { required },
        rate: {required}
      }
      
    },
    mounted() {
      console.log('---------store', this.$store);
    },
    data: () => ({
      loading: false,
      headers: [
        // { text: 'ID', value: '_id' },
        { text: 'Username', value: 'username' },
        { text: 'Banned', value: 'banned' },
        { text: 'Rate', value: 'rate' },
        { text: 'Date', value: 'createdAt' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      defaultItem: {
        username: "",
        password: "",
        rate: "",
      },
      editedItem: {},
      dialogDelete: false,
      dialog: false
    }),
    computed: {
      passwordErrors () {
        const errors = []
        if (!this.$v.editedItem.password.$dirty) return errors
        !this.$v.editedItem.password.minLength && errors.push('Password must be at most 8 characters long')
        !this.$v.editedItem.password.required && errors.push('Password is required.')
        return errors
      },
      usernameErrors () {
        const errors = []
        if (!this.$v.editedItem.username.$dirty) return errors
        !this.$v.editedItem.username.required && errors.push('Username is required')
        return errors
      },
      rateErrors () {
        const errors = []
        if (!this.$v.editedItem.rate.$dirty) return errors
        !this.$v.editedItem.rate.required && errors.push('Rate is required')
        return errors
      },
      invalid() {
        return this.$v.$invalid
      },
      ...mapGetters("user", [
        'users',
      ])
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    methods: {
      async getUsers (query, search_by, order_by, order, limit) {
        this.loading = true;
        await this.$store.dispatch("user/getUsers", {query, search_by, order_by, order, limit});
        this.loading = false
      },
      async addUser () {
        if (this.invalid) return; 
        this.loading = true;
        await this.$store.dispatch("user/addUser", this.editedItem);
        this.loading = false
        this.dialog = false
      },
      addItem() {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        await this.$store.dispatch("user/deleteUser", this.editedItem._id);
        this.closeDelete()
      },


      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {}
        })
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      }
    },
  }
</script>