<template>
  <v-container>

    <v-row
      class="my-4"
    >
      <h2>{{user.username}} </h2>
      <v-spacer></v-spacer>
      <v-btn
          class="mx-4"
          color="primary"
          elevation="2"
          @click="openDialog()"
      >
          Update
      </v-btn>
      <v-btn
          :color="user.banned ? 'success' : 'error'"
          elevation="2"
          @click="banUser()"
      >
          {{ user.banned ? 'Unban User' : 'Ban User' }}
      </v-btn>
    </v-row>

    <v-row
      class="my-4"
    >
      <v-col
        cols="3"
      >
        <v-card
          elevation="2"
          :loading="this.loading"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                Rate
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-h5"
              >{{user.rate }}$ /K</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="grey"
            ></v-list-item-avatar>
          </v-list-item>
        </v-card>

      </v-col>
      <v-col
        cols="3"
      >
        <v-card
          elevation="2"
          :loading="this.loading"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                Unpaid Earnings
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-h5"
              >{{this.user.unpaid_earning }} $</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="grey"
            ></v-list-item-avatar>
          </v-list-item>
        </v-card>

      </v-col>
      <v-col
        cols="3"
      >
        <v-card
          elevation="2"
          :loading="this.loading"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                Total Earnings
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-h5"
              >{{this.user.total_earning}} $</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="grey"
            ></v-list-item-avatar>
          </v-list-item>
        </v-card>
        
      </v-col>
      <v-col
        cols="3"
      >
        <v-card
          elevation="2"
          :loading="this.loading"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                Total R$ Sent
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-h5"
              >{{this.user.total_rbx_sent}} rbx</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="grey"
            ></v-list-item-avatar>
          </v-list-item>
        </v-card>
        
      </v-col>
    </v-row>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card
          class="px-4 py-4"
        >
            <v-card-title class="text-h5">Add New User</v-card-title>
              <v-text-field
                  v-model="editedItem.username"
                  label="Username"
                  :error-messages="usernameErrors"
                  required
                  @input="$v.editedItem.username.$touch()"
                  @blur="$v.editedItem.username.$touch()"
              ></v-text-field>


            <v-text-field
                v-model="editedItem.password"
                :error-messages="passwordErrors"
                label="Password"
                required
                @input="$v.editedItem.password.$touch()"
                @blur="$v.editedItem.password.$touch()"
            ></v-text-field>
              <v-text-field
                v-model="editedItem.rate"
                :error-messages="rateErrors"
                label="Rate"
                type="number"
                required
                @input="$v.editedItem.rate.$touch()"
                @blur="$v.editedItem.rate.$touch()"
            ></v-text-field>
            <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="blue " text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Save</v-btn> -->
            <v-btn
                color="default"
                elevation="2"
                @click="close()"
            >
                Cancel
            </v-btn>
            <v-btn
                color="primary"
                elevation="2"
                @click="updateUser()"
                :disabled="invalid"
            >
                Save
            </v-btn>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
      </v-dialog>


    <div 
      class="small my-8"
      v-if="datacollection"
    >
      <h1
        class="text-xl mb-8"
       >Daily Robux Spent</h1>
      <line-chart
        :chart-data="datacollection"
        style="height: 400px"
        :options="datacollection.options"
       ></line-chart>
    </div>

    <CookiesTable
      :user_id="$route.params.id"
      ref="CookieTable"
    ></CookiesTable>
    <PaymentsTable
      ref="PaymentTable"
      :user_id="$route.params.id"
    ></PaymentsTable>
    <PayoutsTable
      ref="PayoutTable"
      :user_id="$route.params.id"
    ></PayoutsTable>
  </v-container>

</template>

<script>
  
  import PayoutsTable from "../components/PayoutsTable.vue";
  import PaymentsTable from "../components/PaymentsTable.vue";
  import CookiesTable from "../components/CookiesTable.vue";
  import LineChart from "../components/LineChart.js";

  import { mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'

  export default {
    name: 'User',
    components: {
      CookiesTable,
      PaymentsTable,
      PayoutsTable,
      LineChart
    },
    mixins: [validationMixin],
    validations: {
      editedItem: {
        password: { required, minLength: minLength(8)  },
        username: { required, minLength: minLength(3) },
        rate: {required}
      }
    },
    data: () => ({
      loading: true,
      datacollection: null,
      editedItem: {},
      dialog: false
    }),
    mounted() {
      this.getUser()
      this.editedItem = this.user
      this.$refs.CookieTable.getCookies(this.$route.params.id, "user")
      this.$refs.PaymentTable.getPayments(this.$route.params.id, "user")
      this.$refs.PayoutTable.getPayouts(this.$route.params.id, "user")
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    computed: {
      passwordErrors () {
        const errors = []
        if (!this.$v.editedItem.password.$dirty) return errors
        !this.$v.editedItem.password.minLength && errors.push('Password must be at most 8 characters long')
        !this.$v.editedItem.password.required && errors.push('Password is required.')
        return errors
      },
      usernameErrors () {
        const errors = []
        if (!this.$v.editedItem.username.$dirty) return errors
        !this.$v.editedItem.username.minLength && errors.push('Username must be at least 3 characters long')
        !this.$v.editedItem.username.required && errors.push('Username is required')
        return errors
      },
      rateErrors () {
        const errors = []
        if (!this.$v.editedItem.rate.$dirty) return errors
        !this.$v.editedItem.rate.required && errors.push('Rate is required')
        return errors
      },
      invalid() {
        return this.$v.$invalid
      },
      ...mapGetters("user", [
        'user',
      ])
    },
    methods: {
      async getUser() {
        await this.$store.dispatch("user/getUser", this.$route.params.id);
        this.loading = false;
        this.fillData()
      },
      async updateUser() {
        if (this.invalid) return;
        let obj = {
          _id: this.editedItem._id,
          username: this.editedItem.username,
          rate: this.editedItem.rate,
          banned: this.editedItem.banned
        };

        if (this.editedItem.password != this.user.password) {
          obj.password = this.editedItem.password
        } 

        await this.$store.dispatch("user/updateUser", obj);
        this.loading = false;
        this.close()
      },
      fillData () {
        this.datacollection = {
          labels: Object.keys(this.user.daily_rbx_sent),
          datasets: [
            {
              // label: 'Data One',
              // backgroundColor: "transparent",
              // borderColor: "aqua",
              data: Object.values(this.user.daily_rbx_sent)
            }
          ],
          options: {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }]
            },
            legend: {
              display: false //This will do the task
            },
            responsive: true,
            maintainAspectRatio: false,
            // height: "10"
          }
        }
        console.log('----after');
      },
      banUser() {
        this.editedItem = {
          ...this.user,
          banned: !this.user.banned
        }
        this.updateUser()
      },
      openDialog() {
        this.editedItem = Object.assign({}, this.user)
        this.dialog = true
      },
      close() {
        this.dialog = false;
      }
    }
  }
</script>
